export default [
  {
    title: 'Not Authorized',
    route: 'misc-not-authorized',
    target: '_blank',
  },
  {
    title: 'Inicio',
    icon: 'HomeIcon',
    route: 'app-inicio',
    action: 'painel',
  },
  {
    title: 'PDV',
    icon: 'GridIcon',
    route: 'app-pdv',
    action: 'painel',
  },
  {
    title: 'Pedidos',
    icon: 'ShoppingCartIcon',
    route: 'app-pedidos',
    action: 'caixa',
  },
  {
    title: 'Orçamentos',
    icon: 'ShoppingBagIcon',
    route: 'app-orcamento',
    action: 'painel',
  },
  {
    title: 'Produtos',
    icon: 'PackageIcon',
    action: 'admin',
    route: 'app-produtos',
    // children: [
    //   {
    //     title: 'vertodos',
    //     icon: 'PackageIcon',
    //     route: 'app-produtos',
    //     action: 'admin',
    //   },
    //   {
    //     title: 'Categorias',
    //     icon: 'ListIcon',
    //     route: 'app-categorias',
    //     action: 'admin',
    //   },
    // ],
  },
  {
    title: 'Clientes',
    icon: 'UserIcon',
    action: 'admin',
    children: [
      {
        title: 'vertodos',
        route: 'app-clientes',
        action: 'admin',
      },
      {
        title: 'Carteira',
        icon: 'DollarSignIcon',
        route: 'app-carteira',
        action: 'admin',
      },
    ],
  },
  {
    title: 'Fornecedores',
    icon: 'ShoppingBagIcon',
    route: 'app-suppliers',
    action: 'admin',
  },
  {
    title: 'Transportadores',
    icon: 'TruckIcon',
    route: 'app-conveyors',
    action: 'admin',
  },
  {
    title: 'Configuração',
    icon: 'SettingsIcon',
    action: 'painel',
    children: [
      {
        title: 'Usuarios',
        icon: 'UsersIcon',
        route: 'app-usuario-ver',
        action: 'admin',
      },
      {
        title: 'impressora',
        icon: 'SettingsIcon',
        route: 'app-configuracao',
        action: 'painel',
      },
      // {
      //   title: 'Mensagens',
      //   icon: 'MessageSquareIcon',
      //   route: 'app-mensagens',
      //   action: 'admin',
      // },
    ],
  },
]
